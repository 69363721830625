import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const ThirtyFiveMMCoProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              35mm Co.<br />
              One of the hottest products of 2021.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Vintage is cool, everyone knows that. From mum jeans to a classic vinyl player. 35mm Co is another fast growing Melbourne based E-commerce company taking the world by storm. Madison came to Sourci in 2020 with the vision of creating her own personal brand of 35mm reusable film cameras.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Maddison already had a successful business selling other brands’ cameras, but she had greater ambitions and through hearing hundreds of pieces of customer feedback she was ready to design and produce her own brand of cameras.
            </p>
            <p className="font-bold text-xl lg:text-2xl">Enter Sourci. Here's how we made amazing happen for 35mm Co.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/35mm-co-1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-3 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>4 MONTHS</span>
            </p>
            <p className="font-black text-xl">START TO FINISH</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>COMPLETELY CUSTOM</span>
            </p>
            <p className="font-black text-xl">PACKAGING AND BRANDING</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>$50,000</span>
            </p>
            <p className="font-black text-xl">ORDER VALUE</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/35mm-co-3-1875w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Say Cheese.
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              In a short period of time Sourci was able to present research findings and suitable options of different styles of cameras that met the brands exact requirements - a great quality, reusable film camera that only required a single triple A battery to run. Sourci was also coordinating the manufacturing of bespoke packaging that created a seamless and beautiful tactile experience for every customer who opens 35mm Co’s product for the first time.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              It’s also worth mentioning that Sourci and 35mm Co only started working together in February, and in just 4 months a mass production order was being placed. This 4 months included Sourci performing extensive international research, presenting landed costs inclusive of freight, branding and packaging so Madison knew exactly what her costs would be. It even included multiple rounds of pre-production sampling to ensure the product and packaging were genuinely perfect before going into mass production. That's lighting quick. Some would say we did it in a flash!
            </p>
            <p className="font-bold text-2xl lg:text-3xl">We heart control.</p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team.
            <span className="block text-sourci-pink">Push the button. Make amazing happen.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Three key ingredients to scaling manufacturing successfully.
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              The decision to scale your product range and order value can be an exciting and nervous decision. A successful scale up requires three key ingredients which were employed by our experienced Sourci staff for 35mm Co.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              <ul className="list-decimal list-inside ml-4 mt-4">
                <li><span className="font-bold">Evaluating current and potential suppliers</span>, based on prior export experience.</li>
                <li><span className="font-bold">Quality Assurance Programs</span>, that gives you reassurance that they are charting and documenting their quality control measures when doing higher volume and more complex fulfilment, ensuring consistent, premium quality manufactured products.</li>
                <li><span className="font-bold">Production lead times report</span>, covering the period between purchase and the manufacturer completing the order.</li>
              </ul>
            </p>
            <p className="font-bold text-2xl lg:text-3xl">You're in charge here.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/35mm-co-2-1875w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - 35mm Co." />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="35mm-co"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default ThirtyFiveMMCoProject;

export function Head() {
  return (
    <Seo title="35mm Co. | Our Projects | Sourci"
      description="Madison came to Sourci in 2020 with the vision of creating her own personal brand of 35mm reusable film cameras."
      canonical="/projects/35mm-co" />
  )
}